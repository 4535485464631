import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';

import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import { Breakpoints } from '@helpers/constants';

import { HeroProps } from './Hero.types';
import styles from './Hero.module.scss';

const Hero = ({ headline, image }: HeroProps) => {
  const isVisible =
    !headline?.topheading &&
    !headline?.heading &&
    !headline?.subheading &&
    !image?.src;

  const arialabel = [
    headline?.topheading,
    headline?.heading,
    headline?.subheading,
  ].join(' ');

  return (
    <section className={clsx('container', styles.heroContainer)}>
      <BuilderEditingHelper componentName="Hero" visible={isVisible} />
      <div className={styles.headingContainer} data-testid="hero">
        {headline && (
          <h1 aria-label={arialabel}>
            {headline.topheading && (
              <span
                className={styles.topheading}
                data-testid="topheading"
                aria-hidden="true"
              >
                <span>{headline.topheading}</span>
              </span>
            )}
            {headline.heading && (
              <span
                className={
                  headline.switchHeadingStyles === true
                    ? styles.subheading
                    : styles.heading
                }
                data-testid="heading"
                aria-hidden="true"
              >
                {headline.heading}
              </span>
            )}
            {headline.subheading && (
              <span
                className={
                  headline.switchHeadingStyles === true
                    ? styles.heading
                    : styles.subheading
                }
                data-testid="subheading"
                aria-hidden="true"
              >
                {headline.subheading}
              </span>
            )}
          </h1>
        )}
      </div>
      {image?.src && (
        <BuilderImage
          imageSrc={image.src}
          imageSrcMobile={image?.mobileSrc}
          mobileWidth={`${Breakpoints.md}px`}
          tabletWidth={`${Breakpoints.lg}px`}
          desktopWidth={`${Breakpoints.xxl}px`}
          alt={image?.altText}
          classes={styles.backgroundImage}
          data-testid="heroImage"
          disableLazyLoad={true}
        />
      )}
    </section>
  );
};

export default Hero;
